import React from "react";

const ClockIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <rect
        x="11.5"
        y="2.5"
        width="24"
        height="43"
        rx="1.5"
        fill="white"
        stroke="black"
      ></rect>
      <rect x="20" y="7" width="7" height="1" fill="black"></rect>
      <circle cx="23.5" cy="39.5" r="1.5" fill="black"></circle>
    </svg>
  );
};

export default ClockIcon;
